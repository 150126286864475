import React from 'react';

const ExperienceCard = ({ title, company, period, responsibilities }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <div className="text-blue-600 mb-2">{company}</div>
    <div className="text-gray-500 mb-4">{period}</div>
    <ul className="list-disc list-inside space-y-2">
      {responsibilities.map((resp, index) => (
        <li key={index} className="text-gray-600">{resp}</li>
      ))}
    </ul>
  </div>
);

export default ExperienceCard;