// Helper functions for parsing XML sections

// Utility function to safely get text content
const safeGetText = (element, selector) => {
  const node = element?.querySelector(selector);
  return node?.textContent || '';
};

// Utility function to safely get array of elements
const safeQueryAll = (element, selector) => {
  return Array.from(element?.querySelectorAll(selector) || []);
};

export const parseSummarySection = (section) => {
  if (!section) return null;
  return {
    title: safeGetText(section, 'title'),
    icon: safeGetText(section, 'icon'),
    paragraphs: safeQueryAll(section, 'content > paragraph')
      .map(p => p.textContent)
  };
};

export const parseSkillsSection = (section) => {
  if (!section) return null;
  return {
    title: safeGetText(section, 'title'),
    icon: safeGetText(section, 'icon'),
    skillsets: safeQueryAll(section, 'skillsets > skillset')
      .map(skillset => ({
        icon: safeGetText(skillset, 'icon'),
        title: safeGetText(skillset, 'title'),
        skills: safeQueryAll(skillset, 'skills > skill')
          .map(skill => skill.textContent)
      }))
  };
};

export const parseTechStackSection = (section) => {
  if (!section) return null;
  return {
    title: safeGetText(section, 'title'),
    icon: safeGetText(section, 'icon'),
    categories: safeQueryAll(section, 'categories > category')
      .map(category => ({
        name: category.getAttribute('name') || '',
        items: safeQueryAll(category, 'item')
          .map(item => item.textContent)
      }))
  };
};

export const parseExperienceSection = (section) => {
  if (!section) return null;
  return {
    title: safeGetText(section, 'title'),
    icon: safeGetText(section, 'icon'),
    items: safeQueryAll(section, 'items > item')
      .map(item => ({
        title: safeGetText(item, 'title'),
        company: safeGetText(item, 'company'),
        period: safeGetText(item, 'period'),
        details: safeQueryAll(item, 'details > detail')
          .map(detail => detail.textContent)
      }))
  };
};

export const parseCertificationsSection = (section) => {
  if (!section) return null;
  return {
    title: safeGetText(section, 'title'),
    icon: safeGetText(section, 'icon'),
    items: safeQueryAll(section, 'items > item')
      .map(item => ({
        title: safeGetText(item, 'title'),
        description: safeGetText(item, 'description')
      }))
  };
};

export const parseEducationSection = (section) => {
  if (!section) return null;
  return {
    title: safeGetText(section, 'title'),
    icon: safeGetText(section, 'icon'),
    items: safeQueryAll(section, 'items > item')
      .map(item => ({
        title: safeGetText(item, 'title'),
        institution: safeGetText(item, 'institution'),
        year: safeGetText(item, 'year')
      }))
  };
};

export const parseContactSection = (section) => {
  if (!section) return null;
  return {
    title: safeGetText(section, 'title'),
    icon: safeGetText(section, 'icon'),
    personal: {
      name: safeGetText(section, 'personal > name'),
      company: safeGetText(section, 'personal > company'),
      location: safeGetText(section, 'personal > location'),
      email: safeGetText(section, 'personal > email'),
      linkedin: safeGetText(section, 'personal > linkedin')
    },
    availability: {
      services: safeQueryAll(section, 'availability > services > service')
        .map(service => service.textContent),
      message: safeGetText(section, 'availability > message')
    }
  };
};

export const parseIntroSection = (section) => {
  if (!section) return null;
  return {
    text: safeGetText(section, 'content > text')
  };
};