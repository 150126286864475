import React from 'react';

const Footer = () => (
  <footer className="bg-gray-800 text-white py-8">
    <div className="container mx-auto px-4 text-center">
      <p>Detaya Corp since 2002 • Fully Insured</p>
    </div>
  </footer>
);

export default Footer;