import React from "react";
import { Briefcase } from "lucide-react";
import Section from "./common/Section";
import ExperienceCard from "./ExperienceCard";

const Experience = ({ data }) => {
  if (!data) return null;

  return (
    <Section title={data.title} icon={Briefcase}>
      <div className="space-y-8">
        {data.items.map((exp, index) => (
          <ExperienceCard
            key={index}
            title={exp.title}
            company={exp.company}
            period={exp.period}
            responsibilities={exp.details}
          />
        ))}
      </div>

      <p className="mt-4 text-slate-700 pl-2">
        Click{" "}
        <a
          className="text-blue-600 hover:text-blue-800"
          href="/assets/RickPiovesanResumeBrief.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>{" "}
        to download Rick's r&eacute;sum&eacute; summary. <br />
        For a detailed r&eacute;sum&eacute; and references please{" "}
        <a
          className="text-blue-600 hover:text-blue-800"
          href="mailto:rick.piovesan@detaya.com?subject=Contacting Rick Piovesan Consulting"
          rel="noopener noreferrer"
        >
          contact Rick directly
        </a>
        .{" "}
      </p>
    </Section>
  );
};

export default Experience;
