import React from 'react';
import { Mail } from 'lucide-react';
import Section from './common/Section';

const Contact = ({ data }) => {
  if (!data) return null;

  const { personal, availability } = data;

  return (
    <Section title={data.title} icon={Mail} id="contact-section">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-lg font-semibold mb-4">{personal.name}</h3>
            <div className="space-y-2 text-gray-700">
              <p>{personal.company}</p>
              <p>{personal.location}</p>
              <p>
                <a 
                  href={`mailto:${personal.email}?subject=Contact ${personal.name}`} 
                  className="text-blue-600 hover:text-blue-800"
                >
                  {personal.email}
                </a>
              </p>
              <p>
                <a 
                  href={personal.linkedin} 
                  className="text-blue-600 hover:text-blue-800" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  LinkedIn Profile
                </a>
              </p>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Availability</h3>
            <div className="space-y-3 text-gray-700">
              <p>Available for:</p>
              <ul className="list-disc list-inside mb-4">
                {availability.services.map((service, index) => (
                  <li key={index}>{service}</li>
                ))}
              </ul>
              <p className="text-gray-700">
                {availability.message}{' '}
                <a
                  href={`mailto:${personal.email}?subject=Contact ${personal.name}`}
                  className="text-blue-600 hover:text-blue-800"
                >
                  contact Rick directly
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Contact;