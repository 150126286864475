import React from 'react';
import { Menu } from 'lucide-react';
import { smoothScrollToElement } from './utils/scrollUtils';

//             className="w-24 h-24 md:w-32 md:h-32 rounded-full object-cover border-4 border-gray-100 shadow-lg object-center"


const Header = () => {
  return (
    <header className="relative bg-white shadow-md py-8 mt-4">
      <div className="container mx-auto px-4 flex items-center justify-between">
        <div className="flex items-center space-x-6">
          <img
            src="/assets/rick-profile.jpg"
            alt="Rick Piovesan"
            className="h-24 md:h-32 rounded-full object-cover border-4 border-gray-100 shadow-lg object-center"
            rel="noopener noreferrer"
          />
          <div>
            <h1 className="text-3xl md:text-4xl font-bold text-gray-800">
              Rick Piovesan
            </h1>
            <p className="text-lg text-gray-600">
              Enterprise Solutions Consultant
            </p>
          </div>
        </div>

        {/* Menu Button */}
        <button
          onClick={() => smoothScrollToElement('contact-section')}
          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-2 md:text-lg md:px-6"
        >
          <Menu className="h-5 w-5 mr-2" />
          Contact
        </button>
      </div>
    </header>
  );
};

export default Header;