import React from "react";
import { GraduationCap } from "lucide-react";
import Section from "./common/Section";

const Education = ({ data }) => {
  if (!data) return null;

  return (
    <Section title={data.title} icon={GraduationCap}>
      {data.items.map((edu, index) => (
        <div key={index} className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-2">
            {edu.title}
          </h3>
          <p className="text-gray-600">{edu.institution}, {edu.year}</p>
        </div>
      ))}
    </Section>
  );
};

export default Education;