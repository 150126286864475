import React from "react";
import { UserSquare2 } from "lucide-react";
import Section from "./common/Section";

const Summary = ({ introData, summaryData }) => {
  if (!introData || !summaryData) return null;

  return (
    <>
      <section>
        <div className="bg-slate-200 p-6 mb-12 rounded-lg shadow-md">
          <p className="text-gray-700 text-lg leading-relaxed italic">
            {introData.text}
          </p>
        </div>
      </section>
      <Section title={summaryData.title} icon={UserSquare2}>
        <div className="bg-white p-6 rounded-lg shadow-md">
          {summaryData.paragraphs.map((paragraph, index) => (
            <p key={index} className="text-gray-700 text-lg leading-relaxed mb-4 last:mb-0">
              {paragraph}
            </p>
          ))}
        </div>
      </Section>
    </>
  );
};

export default Summary;