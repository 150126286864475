import React from "react";
import { Award } from 'lucide-react';
import Section from './common/Section';
import CertificationCard from "./CertificationCard";

const Certifications = ({ data }) => {
  if (!data) return null;

  return (
    <Section title={data.title} icon={Award}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {data.items.map((cert, index) => (
          <CertificationCard
            key={index}
            title={cert.title}
            description={cert.description}
          />
        ))}
      </div>
    </Section>
  );
};

export default Certifications;