import React from "react";
import { Star } from 'lucide-react';
import Section from './common/Section';
import * as Icons from "lucide-react";
import SkillCard from "./SkillCard";

const Skills = ({ data }) => {
  if (!data) return null;

  return (
    <Section title={data.title} icon={Star}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {data.skillsets.map((skillset, index) => {
          const IconComponent = Icons[skillset.icon];
          return (
            <SkillCard
              key={index}
              icon={<IconComponent className="w-8 h-8 text-blue-600" />}
              title={skillset.title}
              skills={skillset.skills}
            />
          );
        })}
      </div>
    </Section>
  );
};

export default Skills;