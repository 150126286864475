import React from 'react';
import { Layers } from 'lucide-react';
import Section from './common/Section';

const TechCategory = ({ title, items }) => (
  <div className="mb-6">
    <h3 className="text-lg font-semibold mb-3 text-gray-800">{title}</h3>
    <div className="flex flex-wrap gap-2">
      {items.map((item, index) => (
        <span 
          key={index} 
          className="bg-white px-3 py-1 rounded-full text-sm text-gray-700 border border-gray-200 shadow-sm"
        >
          {item}
        </span>
      ))}
    </div>
  </div>
);

const TechnologyStack = ({ data }) => {
  if (!data) return null;

  return (
    <Section title={data.title} icon={Layers}>
      <div className="bg-gray-50 p-6 rounded-lg shadow-md space-y-6">
        {data.categories.map((category, index) => (
          <TechCategory 
            key={index} 
            title={category.name} 
            items={category.items} 
          />
        ))}
      </div>
    </Section>
  );
};

export default TechnologyStack;