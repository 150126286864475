import React from 'react';

const Section = ({ title, icon: Icon, children, id }) => (
  <section className="mb-16" id={id}>
    <div className="flex items-center mb-8">
      <Icon className="w-8 h-8 text-blue-600 mr-3" />
      <h2 className="text-2xl font-bold">{title}</h2>
    </div>
    {children}
  </section>
);

export default Section;