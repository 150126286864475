import React, { useState, useEffect } from 'react';
import Header from './Header';
import Summary from './Summary';
import Skills from './Skills';
import Experience from './Experience';
import Certifications from './Certifications';
import Education from './Education';
import Contact from './Contact';
import TechnologyStack from './TechnologyStack';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import * as xmlHelpers from './utils/xmlHelpers';

const PortfolioWebsite = () => {
  const [resumeData, setResumeData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResumeData = async () => {
      try {
        const response = await fetch('/assets/portfolio-data.xml');
        const xmlText = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, 'text/xml');
        
        // Debug: Log all sections found in XML
        const sections = Array.from(xmlDoc.querySelectorAll('section'));
        console.log('Found sections:', sections.map(s => ({
          id: s.getAttribute('id'),
          type: s.getAttribute('type')
        })));
        
        // Parse each section with its specific helper
        const parsedData = {
          intro: xmlHelpers.parseIntroSection(xmlDoc.querySelector('section[id="company-intro"]')),
          summary: xmlHelpers.parseSummarySection(xmlDoc.querySelector('section[id="summary"]')),
          skills: xmlHelpers.parseSkillsSection(xmlDoc.querySelector('section[id="skills"]')),
          techstack: xmlHelpers.parseTechStackSection(xmlDoc.querySelector('section[id="techstack"]')),
          experience: xmlHelpers.parseExperienceSection(xmlDoc.querySelector('section[id="experience"]')),
          certifications: xmlHelpers.parseCertificationsSection(xmlDoc.querySelector('section[id="certifications"]')),
          education: xmlHelpers.parseEducationSection(xmlDoc.querySelector('section[id="education"]')),
          contact: xmlHelpers.parseContactSection(xmlDoc.querySelector('section[id="contact"]'))
        };

        setResumeData(parsedData);
        setLoading(false);
      } catch (err) {
        console.error('Error loading resume data:', err);
        setError({
          message: 'Failed to load resume data',
          details: err.message,
          timestamp: new Date().toISOString()
        });
        setLoading(false);
      }
    };

    fetchResumeData();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center text-red-600">
        Error loading resume data. Please try refreshing the page.
      </div>
    );
  }

  return (
    <div className="relative min-h-screen">
      {/* Background layers */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-200 via-gray-50 to-purple-200" />
      <div className="absolute inset-0 texture-grid opacity-70" />
      
      {/* Content */}
      <div className="relative min-h-screen">
        <Header />
        <main className="container mx-auto px-4 py-12">
          <Summary introData={resumeData.intro} summaryData={resumeData.summary} />
          <Skills data={resumeData.skills} />
          <TechnologyStack data={resumeData.techstack} />
          <Experience data={resumeData.experience} />
          <Certifications data={resumeData.certifications} />
          <Education data={resumeData.education} />
          <Contact data={resumeData.contact} />
        </main>
        <Footer />
        <ScrollToTop />
      </div>
    </div>
  );
};

export default PortfolioWebsite;